import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { EGroupPartition } from 'api/groups/types';

import { EmptyGroupList as EmptyGroupListComponent } from 'Groups/Widget/EmptyGroupList';

export function EmptyGroupList() {
  const { isMobile } = useEnvironment();

  return (
    <EmptyGroupListComponent
      filters={{}}
      variant="page"
      partition={EGroupPartition.ALL}
    />
  );
}

EmptyGroupList.displayName = 'EmptyGroupList';
