import { vars } from '../helpers';
import * as convertors from '../convertors';

export const getGroupsListWidgetCssVars = vars(
  ({ styleParams: { numbers, booleans } }) => {
    return {
      'item-text-justify-content': convertors.justifyContent(
        numbers.itemTextAlignment,
      ),
      'item-text-align-items': convertors.alignItems(numbers.itemTextAlignment),
      'group-activity-display': convertors.display(booleans.showActivity),
      'group-type-display': convertors.display(booleans.showGroupType),
      'group-actions-display': convertors.display(booleans.showGroupButton),
    };
  },
);
