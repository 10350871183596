import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

import commonStyles, {
  customCssVars as commonCssVars,
} from 'Groups/stylesParams';

export const WIDGET_MAX_SPACING = 100;

const stylesParams = {
  sideBySideCardSpacing: createStylesParam('sideBySideCardSpacing', {
    getDefaultValue: () => 12,
    type: StyleParamType.Number,
  }),
};

export default { ...commonStyles, ...stylesParams };

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (params) => {
  return {
    ...commonCssVars(params),
  };
};
