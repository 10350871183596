import React from 'react';
import { useSelector } from 'react-redux';

import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

import { useSettings } from '@wix/tpa-settings/react';
import { EGroupListSort, ELayoutType } from 'settings/constants';
import { GroupsSelectorType } from 'settings/groups-list-widget/settings';
import settingsParams from 'GroupsListWidget/settingsParams';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { selectGroups } from 'store/selectors';

import { EGroupPartition } from 'api/groups/types';

import { ListLayout } from './ListLayout';
import { GridLayout } from './GridLayout';
import { SideBySideLayout } from './SideBySideLayout';

import { LayoutProps } from './types';

type LayoutRootProps = {};

export const LayoutRoot: React.FC<LayoutRootProps> = () => {
  const settings = useSettings();

  const groupsSelector = settings.get(settingsParams.groupsSelectorType);
  const groupIdsToShow = settings.get(settingsParams.groupIdsToShow);

  const { isMobile } = useEnvironment();

  const { groups$ } = useController();
  const state = useSelector(selectGroups(EGroupPartition.ALL));

  const limit = 6;
  const layout = settings.get(settingsParams.groupListLayout);
  const sortBy = settings.get(settingsParams.sortBy) as EGroupListSort;

  const layoutProps: LayoutProps = {
    ...state,
    onRetry: handleRetry,
    onLoadMore: handleLoadMore,
    batchLength:
      groupsSelector === GroupsSelectorType.ALL
        ? limit
        : Math.min(limit, getGroupIdsToShow().length),
  };

  useDidUpdate(() => {
    groups$.query({
      limit,
      sort: sortBy,
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
      groupIds: getGroupIdsToShow(),
    });
  }, [limit, sortBy, groupIdsToShow, groupsSelector]);

  switch (layout) {
    case ELayoutType.Grid:
      return <GridLayout {...layoutProps} />;

    case ELayoutType.SideBySide:
      return isMobile ? (
        <GridLayout {...layoutProps} />
      ) : (
        <SideBySideLayout {...layoutProps} />
      );

    case ELayoutType.ListWidget:
    default:
      return <ListLayout {...layoutProps} />;
  }

  function handleRetry() {
    groups$.query({
      limit,
      offset: 0,
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
      sort: sortBy,
      groupIds: getGroupIdsToShow(),
    });
  }

  function handleLoadMore() {
    groups$.query({
      limit,
      offset: state.groups.length,
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
      sort: sortBy,
      groupIds: getGroupIdsToShow(),
    });
  }

  function getGroupIdsToShow() {
    switch (groupsSelector) {
      case GroupsSelectorType.ALL:
        return undefined;
      case GroupsSelectorType.CUSTOM:
        try {
          return JSON.parse(settings.get(settingsParams.groupIdsToShow));
        } catch (e: any) {
          return [];
        }
    }
  }
};

LayoutRoot.displayName = 'LayoutRoot';
