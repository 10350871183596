import { StyleParamType, createStylesParam } from '@wix/tpa-settings';
import { EAlignment, EImageCrop, EImageLayout } from 'settings/constants';

export const groupsListWidgetStylesParams = {
  /**
   * Design styles
   */
  imageCrop: createStylesParam('imageCrop', {
    type: StyleParamType.Number,
    getDefaultValue: () => EImageCrop.Crop,
  }),
  imageLayout: createStylesParam('imageLayout', {
    type: StyleParamType.Number,
    getDefaultValue: () => EImageLayout.LTR,
  }),
  itemTextAlignment: createStylesParam('itemTextAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => EAlignment.Left,
  }),
  showActivity: createStylesParam('showActivity', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showGroupButton: createStylesParam('showGroupButton', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
