import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { UISref } from '@wix/tpa-router/react';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { EImageCrop, EImageRatio } from 'settings/constants';

import { IGroup } from 'store/groups';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { Show } from 'wui/Show';
import { Box } from 'wui/Box';

import { Link } from 'common/components/Link';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';
import { RoleIcon } from 'common/components/RoleIcon';
import { useBiParams } from 'common/hooks/useBiParams';

import { GroupGridItemAction } from 'Groups/Widget/Layout/GridLayout/GroupGrid/GroupGridItem/GroupGridItemAction';
import widgetStylesParams from 'GroupsListWidget/stylesParams';

import { getDirection } from './helpers';

import classes from './GroupStripeItem.scss';

interface IGroupStripeItemProps {
  group: IGroup;
  even?: boolean;
}

export function GroupStripeItem({ group, even }: IGroupStripeItemProps) {
  const { isMobile } = useEnvironment();
  const biParams = useBiParams();

  const styles = useStyles();

  const canCrop = styles.get(widgetStylesParams.imageCrop) === EImageCrop.Crop;

  return (
    <Card
      gap="SP1"
      padding="SP0"
      height="240px"
      direction={getDirection(styles.get(widgetStylesParams.imageLayout), even)}
    >
      <UISref
        state="group"
        params={{ slug: group.slug }}
        bi={groupsLivesiteClick({
          screen_name: biParams.groupsScreenWithTab(),
          button_name: 'click_on_group',
        })}
      >
        <a className={classes.imageLink}>
          <GroupImage
            fluid
            aspectRatio={EImageRatio.Rectangle}
            image={group.coverImage?.image}
            resize={canCrop ? 'cover' : 'contain'}
          />
        </a>
      </UISref>

      <CardContent
        width="100%"
        verticalAlign="middle"
        className={classes.content}
      >
        <Box verticalAlign="middle" className={classes.alignedBox}>
          <Link
            state="group"
            className={classes.title}
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            {group.name}
          </Link>
          <Show if={!isMobile}>
            <RoleIcon role={group.role} />
          </Show>
        </Box>

        <GroupInfo
          wired
          groupId={group.id as string}
          className={classes.alignedBox}
        />

        <GroupGridItemAction
          group={group}
          fullWidth={false}
          className={classes.action}
        />
      </CardContent>
    </Card>
  );
}

GroupStripeItem.displayName = 'GroupStripeItem';
